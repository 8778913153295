import React from 'react'

export const Twitter = ({
	width = '13',
	height = '14',
	color = '#fff',
	className = '',
}) => (
	<svg
		className={className}
		width={width}
		height={height}
		viewBox="0 0 16 16"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill={color}
			d="M12.656 3.188q-.523.766-1.266 1.305.008.109.008.328 0 1.016-.297 2.027t-.902 1.941-1.441 1.645-2.016 1.141-2.523.426q-2.117 0-3.875-1.133.273.031.609.031 1.758 0 3.133-1.078-.82-.016-1.469-.504t-.891-1.246q.258.039.477.039.336 0 .664-.086-.875-.18-1.449-.871T.844 5.548v-.031q.531.297 1.141.32-.516-.344-.82-.898T.86 3.736q0-.688.344-1.273.945 1.164 2.301 1.863t2.902.777q-.062-.297-.062-.578 0-1.047.738-1.785t1.785-.738q1.094 0 1.844.797.852-.164 1.602-.609-.289.898-1.109 1.391.727-.078 1.453-.391z"
		/>
	</svg>
)
