module.exports = {
  defaultTitle: 'Elom Tsiagbey',
  logo: 'https://elom.dev/favicon/logo-48.png',
  author: 'Elom T',
  url: 'https://elom.dev',
  legalName: 'Elom',
  defaultDescription:
    "I'm Elom, a full stack developer, interested in the web & tech in general.",
  socialLinks: {
    twitter: 'http://www.twitter.com/mrelomt',
    github: 'https://github.com/elomt',
    linkedin: 'https://www.linkedin.com/in/elom-tsiagbey',
    instagram: 'https://instagram.com/elom.t',
  },
  googleAnalyticsID: 'UA-158637415-1',
  themeColor: '#00c6ff',
  backgroundColor: '#00c6ff',
  siteRss: '/rss.xml',
  social: {
    facebook: '',
    twitter: '@mrelomt',
  },
  address: {
    city: 'Ottawa',
    region: 'Ontario',
    country: 'Canada',
    zipCode: 'K2J5C4',
  },
  contact: {
    email: 'elom@live.ca',
  },
  foundingDate: '2016',
}
