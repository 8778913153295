import React from 'react'

export const Logo = ({ color, className, strokeWidth }) => (
  <svg
    className={className}
    viewBox="0 0 61 45"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="#ff0000ff">
      <path
        fill="#ffffff"
        stroke="#000000"
        strokeWidth={strokeWidth}
        opacity="1.00"
        d=" M 0.00 0.99 C 9.00 1.00 18.00 1.01 27.00 1.00 C 27.00 15.33 27.00 29.66 27.00 44.00 C 22.67 44.00 18.33 44.00 14.00 44.01 C 13.99 33.01 14.00 22.00 14.00 11.00 C 9.33 11.00 4.67 11.00 0.00 11.00 L 0.00 0.99 Z"
      />
    </g>
    <g id="#000000ff">
      <path
        fill={color}
        stroke="#000000"
        strokeWidth={strokeWidth}
        opacity="1.00"
        d=" M 27.00 1.00 C 37.67 0.99 48.33 1.01 59.00 1.00 C 59.00 3.50 59.00 8.50 59.00 11.00 C 51.67 11.00 44.33 11.00 37.00 11.00 L 37.00 16.00 C 44.00 16.00 51.00 16.00 58.00 16.00 C 58.00 18.50 58.00 23.50 58.00 26.00 C 51.00 26.00 44.00 26.00 37.00 26.00 C 37.00 28.67 37.00 31.33 37.00 34.00 C 44.67 34.00 52.33 34.00 60.00 34.00 C 60.00 36.50 60.00 41.50 60.00 44.00 C 49.00 44.00 38.00 43.99 27.00 44.00 C 27.00 29.66 27.00 15.33 27.00 1.00 Z"
      />
    </g>
  </svg>
)
